import { Controller } from "@hotwired/stimulus"

/* for select toggler design component */
export default class extends Controller {
  static targets = [
    "select" /* the select element that will toggle content */,
    "toggled" /* if the toggle content includes form elements, this is for identifying them to enable/disable */,
  ]

  static values = {
    connectors: Object /* hash | key: dom ID, value: select option value */,
    defaultId: String /* the dom ID of the default content that will show */,
  }

  initialize() {
    if (this.hasSelectTarget) {
      this.hideClass = "hidden"
      this.showClass = "block"

      this.values = Object.values(this.connectorsValue)
      this.keys = Object.keys(this.connectorsValue)
      this.hash = Object.entries(this.connectorsValue)

      const currentlySelectedValue = this.selectTarget.value
      const hasSelectedValue = this.values.includes(currentlySelectedValue)

      // show corresponding element with selected value on page load
      this.hash.forEach(([domId, optValue]) => {
        const element = document.getElementById(domId)
        if (hasSelectedValue && optValue === currentlySelectedValue) {
          this.show(element)
        } else if (!hasSelectedValue && domId === this.defaultIdValue) {
          this.show(element)
        } else {
          this.hide(element)
        }
      })
    }
  }

  show(target) {
    if (target === null) {
      return
    }

    target.classList.remove(this.hideClass)
    target.classList.add(this.showClass)

    const form_elements = target.querySelectorAll('[data-select-toggler-target="toggled"]')
    form_elements.forEach((fe) => {
      fe.disabled = false
    })
  }

  hide(target) {
    if (target === null) {
      return
    }

    target.classList.add(this.hideClass)
    target.classList.remove(this.showClass)

    const form_elements = target.querySelectorAll('[data-select-toggler-target="toggled"]')
    form_elements.forEach((fe) => {
      fe.disabled = true
    })
  }

  toggle(event) {
    const selectedValue = event.target.value

    if (!this.values.includes(selectedValue)) {
      this.keys.forEach((domId) => {
        const element = document.getElementById(domId)
        this.hide(element)
      })
      this.show(document.getElementById(this.defaultIdValue))
      return
    }

    this.hash.forEach(([domId, optValue]) => {
      const element = document.getElementById(domId)
      if (optValue === selectedValue) {
        this.show(element)
      } else {
        this.hide(element)
      }
    })
  }
}
