import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["nav", "section"]

  showSection(event) {
    this.navTargets.forEach((nav) => {
      nav.classList.remove("border-gray-300", "shadow-sm", "border-gray-300")
      nav.classList.add("border-transparent")
    })

    event.currentTarget.classList.remove("border-transparent")
    event.currentTarget.classList.add("border-gray-300", "shadow-sm")

    const sectionId = event.params.id.toString()

    this.sectionTargets.forEach((section) => {
      section.classList.add("hidden")
    })

    this.sectionTargets.find((section) => section.dataset.sectionId.toString() === sectionId).classList.remove("hidden")
  }
}
