import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab"]

  get contentTargets() {
    return document.querySelectorAll("[data-new-questionnaire-tab]")
  }

  changeTab(e) {
    if (e.currentTarget.ariaPressed === "true") return

    const activeContentId = e.currentTarget.getAttribute("aria-controls")
    const activeContent = document.getElementById(activeContentId)

    this.tabTargets.forEach((tab) => {
      tab.setAttribute("aria-pressed", "false")
      tab.classList.remove("border-blue-500")
      tab.classList.add("border-transparent")
      tab.classList.add("hover:border-blue-200")
    })

    e.currentTarget.setAttribute("aria-pressed", "true")
    e.currentTarget.classList.add("border-blue-500")
    e.currentTarget.classList.remove("border-transparent")
    e.currentTarget.classList.remove("hover:border-blue-200")

    this.contentTargets.forEach((content) => {
      content.classList.add("hidden")
    })

    activeContent.classList.remove("hidden")
  }
}
