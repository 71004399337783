import { Controller } from "@hotwired/stimulus"

/**
 * Manages the dynamic interactions for workforce report configuration forms.
 *
 * This controller handles two main responsibilities:
 * 1. Prevents duplicate selections between row and column headers in report layouts
 *    by disabling options that are already selected in the other selector.
 * 2. Manages chart option availability across line, pie, and bar charts based on
 *    the available data, enabling/disabling options as appropriate.
 *
 */
export default class extends Controller {
  static targets = ["rowSelector", "columnSelector", "pieChartOptions", "lineChartOptions", "barChartOptions"]

  connect() {
    if (this.hasRowSelectorTarget && this.hasColumnSelectorTarget) {
      this.preventRowsAndColumnDuplicateSelections(this.rowSelectorTarget)
      this.preventRowsAndColumnDuplicateSelections(this.columnSelectorTarget)
    }
  }

  updateHeaderSelections(e) {
    this.preventRowsAndColumnDuplicateSelections(e.target)
  }

  preventRowsAndColumnDuplicateSelections(headerSelector) {
    if (!this.hasRowSelectorTarget || !this.hasColumnSelectorTarget) {
      return
    }

    let otherHeaderSelector = null
    if (headerSelector === this.rowSelectorTarget) {
      otherHeaderSelector = this.columnSelectorTarget
    } else {
      otherHeaderSelector = this.rowSelectorTarget
    }

    otherHeaderSelector.options.forEach((option) => {
      if (option.value === headerSelector.value) {
        option.disabled = true
      } else {
        option.disabled = false
      }
    })
  }

  updateChartDataSelections({ detail: { content } }) {
    const options = [
      ...this.lineChartOptionsTarget.options,
      ...this.pieChartOptionsTarget.options,
      ...this.barChartOptionsTarget.options,
    ]

    options.forEach((option) => {
      if (option.value === "") return

      option.disabled = !content.includes(option.value)
    })
  }
}
