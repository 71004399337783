import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sectionName", "sectionNameForm"]

  editSectionName(event) {
    this.sectionNameTarget.classList.add("hidden")
    this.sectionNameFormTarget.classList.remove("hidden")
  }
}
