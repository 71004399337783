import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "characters",
    "number",
    "currency",
    "date",
    "time",
    "dateTime",
    "multipleChoice",
    "correctAnswer",
    "answerType",
  ]

  static values = {
    item: Object,
    questionId: String,
    hasCorrectAnswer: Boolean,
  }

  connect() {
    if (this.hasItemValue) {
      this.showHide({ target: { value: this.itemValue.type } })
    }
  }

  toggleCorrectAnswer(event) {
    this.hasCorrectAnswerValue = event.target.checked

    // Get the current answer type
    const currentType = this.answerTypeTargets.find(
      (t) => t.dataset.questionId === event.target.dataset.questionId
    ).value

    // Update visibility of the answer field for the current type
    this.updateAnswerFieldVisibility(currentType, event.target.dataset.questionId)
  }

  updateAnswerFieldVisibility(type, questionId) {
    // Find the target that matches the current type
    const targetMap = {
      characters: this.charactersTargets.find((t) => t.dataset.questionId === questionId),
      number: this.numberTargets.find((t) => t.dataset.questionId === questionId),
      currency: this.currencyTargets.find((t) => t.dataset.questionId === questionId),
      date: this.dateTargets.find((t) => t.dataset.questionId === questionId),
      time: this.timeTargets.find((t) => t.dataset.questionId === questionId),
      date_time: this.dateTimeTargets.find((t) => t.dataset.questionId === questionId),
      multiple_choice: this.multipleChoiceTargets.find((t) => t.dataset.questionId === questionId),
    }

    const target = targetMap[type]
    if (target) {
      if (this.hasCorrectAnswerValue) {
        target.classList.remove("hidden")
        target.removeAttribute("disabled")
      } else {
        target.classList.add("hidden")
        target.setAttribute("disabled", "true")
      }
    }
  }

  showHide(e) {
    const value = e.target.value
    const questionId = e.target.dataset.questionId

    // Hide all targets for this question
    const allTargets = [
      ...this.multipleChoiceTargets,
      ...this.charactersTargets,
      ...this.numberTargets,
      ...this.currencyTargets,
      ...this.dateTargets,
      ...this.timeTargets,
      ...this.dateTimeTargets,
    ].filter((target) => target.dataset.questionId === questionId)

    allTargets.forEach((target) => {
      if (target) {
        target.classList.add("hidden")
        target.setAttribute("disabled", "true")
      }
    })

    // Show and enable the selected target
    const targetMap = {
      characters: this.charactersTargets.find((t) => t.dataset.questionId === questionId),
      number: this.numberTargets.find((t) => t.dataset.questionId === questionId),
      currency: this.currencyTargets.find((t) => t.dataset.questionId === questionId),
      date: this.dateTargets.find((t) => t.dataset.questionId === questionId),
      time: this.timeTargets.find((t) => t.dataset.questionId === questionId),
      dateTime: this.dateTimeTargets.find((t) => t.dataset.questionId === questionId),
      multiple_choice: this.multipleChoiceTargets.find((t) => t.dataset.questionId === questionId),
    }

    const selectedTarget = targetMap[value]
    if (selectedTarget) {
      // Get the correct answer checkbox state for this question
      const correctAnswerCheckbox = this.correctAnswerTargets.find((t) => t.dataset.questionId === questionId)

      if (correctAnswerCheckbox?.checked) {
        selectedTarget.classList.remove("hidden")
      }
      // Always remove disabled attribute for the selected target
      selectedTarget.removeAttribute("disabled")
    }
  }

  addOption(e) {
    e.preventDefault()
    const questionId = e.target.dataset.questionId
    const optionsContainer = this.multipleChoiceTargets
      .find((t) => t.dataset.questionId === questionId)
      .querySelector(".multiple-choice-options")
    const newOption = optionsContainer.querySelector(".flex.items-center.space-x-2").cloneNode(true)
    const input = newOption.querySelector("input[type='text']")
    input.value = ""
    input.name = input.name.replace(/\[\d+\]/, `[${optionsContainer.children.length}]`)
    optionsContainer.appendChild(newOption)
  }
}
